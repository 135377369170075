.p-cal {
  width: 292px;
  background-image: url(../images/common/bg_cal_top.png), url(../images/common/bg_cal_bottom.png);
  background-repeat: no-repeat;
  background-position: top left, bottom left;
  padding: {
    top: 7px;
    bottom: 17px;
  }

  &__box {
      background-image: url(../images/common/bg_cal.png);
      background-repeat: repeat-y;
      padding: {
        right: 11px;
        left: 7px;
      }
    }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__nav-text {
      font-weight: bold;
      font-size: 18px;
    }
    @mixin nav {
      font-size: 0;
      width: 33px;
      height: 32px;
      background-position: center center;
      background-image: url(../images/common/icon_cal_arrow_green.png);

    }
    &__nav-next {
      @include nav;
    }
    &__nav-prev {
      @include nav;
      transform: rotate(180deg);
    }
    &__nav-not {
      background-image: url(../images/common/icon_cal_arrow_gray.png);
      &:hover {
        opacity: 1;
      }
    }

  }
  &__table {
    width: 100%;
    font-size: 16px;
    & th {
      height: 38px;
      font-weight: normal;
    }
    & td {
      height: 39px;
      text-align: center;
      border: solid 1px $bdGray;
    }
  }
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 38px;
    &:hover {
      opacity: 1;
      @include pcMix {
        background-color: #d0eabc;
      }
      @include smartphone {
        transition : 0s;
      }
    }
    
    &--active {
      color: white;
      background-color: #65b820;
      &:hover {
        background-color: #65b820;
      }
    } 
    &--sun {
      background-color: #ffebeb;
    } 
    &--sat {
      background-color: #e8f0f7;
    }
    &__another-month {
      color: #cccccc;
    }
    &__not-select {
      background-color: #f5f5f2;
      &:hover {
        background-color: #f5f5f2;
      }
    }

  }
}