.p-slider {
  width: 100%;
  height: 400px;
  position: relative;

  @include smartphone {
    width: 100%;
    height: auto;
  }

  &__img {
    @include pcMix {
      width: 100%;
      height: 400px;
      object-fit: cover;
      padding: {
        left: 12px;
      }
      margin: {
        bottom: 12px;
      }

    }
    @include smartphone {
      width: 100%;
      height: get_vh(620);
      margin: {
        bottom: get_vh(10);
      }
    }
  }
  &__item {
    width: 100%;
    height: auto;
  }

  &__button {
    font-size: 0;
    cursor: pointer;

    &--next {
      @include pcMix {
      position: absolute;
      top: calc(50% - 7px);
      right: -16px;
      width: 10px;
      height: 14px;
      background: url(../images/common/pc_icon_slider_right.png) left top no-repeat;
      background-size: contain;
      }

      @include smartphone {
        position: absolute;
        width: get_vw(20);
        height: get_vh(34);
        right: get_vw(-30);
        top: calc(50% - #{get_vh(17)});
        background: url(../images/common/sp_icon_slider_right.png) left top no-repeat;
        background-size: contain;
      }
      &:hover {
        cursor: pointer;
      }

    }
    &--prev {
      @include pcMix {
        position: absolute;
        top: calc(50% - 7px);
        left: -8px;
        width: 10px;
        height: 14px;
        background: url(../images/common/pc_icon_slider_left.png) left top no-repeat;
        background-size: contain;
      }

      @include smartphone {
        position: absolute;
        width: get_vw(20);
        height: get_vh(34);
        left: get_vw(-30);
        top: calc(50% - #{get_vh(17)});
        background: url(../images/common/sp_icon_slider_left.png) left top no-repeat;
        background-size: contain;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__thumbnail {
    @include smartphone {
      margin: {
        bottom: get_vh(40);
      }
    }
    &__item {
      @include pcMix {
        width: 90px;
        height: 90px;
        margin: {
          left: 12px;
        }
      }
      @include smartphone {
        width: calc(100% - #{get_vw(30)} / 4);
      }
    }
    &__img {
      @include pcMix {
        width: 90px;
        height: 90px;
        object-fit: cover;
      }
      @include smartphone {
        width: calc(100% - #{get_vw(30)} / 4);
      }
      &:hover {
        cursor: pointer;
      }
    }

  }
}