.c-form {
  &__select {
    width: 100%;
    @include pcMix {
      height: 40px;
      padding: {
        left: 12px;
        right: 12px;
      }
      background-image: url(../images/common/pc_icon_drop-down_green.png);
      background-repeat: no-repeat;
      background-position: right 12px center;  
    }
    @include smartphone {
      height: get_vh(100);
      padding: {
        left: get_vw(30);
        right: get_vw(30);
      }
      background-image: url(../images/common/sp_icon_drop-down_green.png);
      background-repeat: no-repeat;
      background-position: right 12px center;
      background-size: get_vw(34);
    }
    &--small {
      @include pcMix {
        width: 40%;
        min-width: 100px;
      }
      @include smartphone {
        width: get_vw(305);
      }
    }
    &__add-box {
      @include pcMix {
        padding: {
          top: 20px;
        }
      }
      @include smartphone {
        padding: {
          top: get_vh(40);
        }
      }
    }
  }
  &__table {
    width: 100%;
    @include pcMix {
      font-size: 16px;
    }

    & th {
      @include pcMix {
        text-align: left;
        width: 280px;
        height: 120px;
        background-color: $beige;
        border-top: solid 4px $beige;
        border-bottom: solid 4px $beige;
        padding: {
          left: 40px;
          right: 40px;
        }
        background-image: url(../images/common/bg_th-white.png),url(../images/common/bg_th-white.png);
        background-position: left top, left bottom;
        background-repeat: repeat-x, repeat-x;
      }
      @include smartphone {
        display: block;
        width: 100%;
        height: get_vh(100);
        background-color: $beige;
        text-align: left;
        padding: {
          left: get_vw(40);
        }
        display: flex;
        align-items: center;
      }
    }
    & td {
      @include pcMix {
        height: 160px;
        border-top: solid 4px $beige;
        border-bottom: solid 4px $beige;
        padding: {
          top: 30px;
          left: 40px;
          bottom: 30px;
        }
      }
      @include smartphone {
        @include fz_vw (30);
        display: block;
        width: 100%;
        padding: {
          top: get_vh(30);
          bottom: get_vh(60);
        }
      }

    }
    & input[type=text] {
      border: solid 1px $bdBeige;
      @include pcMix {
        padding: 12px;
      }
    }
    & textarea {
      border: solid 1px $bdBeige;
      @include pcMix {
        width: 654px;
        height: 160px;
        padding: 12px;
      }
      @include smartphone {
        width: 100%;
        height: get_vh(300);
        padding: get_vw(30);
      }
    }
  }
  &__input-text {
    width: 280px;
    @include pcMix {
      height: 40px;
      padding: {
        left: 12px;
        right: 12px;
      }
    }
    @include smartphone {
      height: get_vh(100);
      padding: {
        left: 12px;
        right: 12px;
      }
    }
    &--flex {
      width: 100%;
      @include pcMix {
        height: 40px;
        padding: {
          left: 12px;
          right: 12px;
        }
      }
    }
    &--middle {
      @include pcMix {
        width: 200px;
        height: 40px;
        padding: {
          left: 12px;
          right: 12px;
        }  
      }
      @include smartphone {
        height: get_vh(100);
        padding: {
          left: 12px;
          right: 12px;
        }
      }
    }
  }
  &__box {
    &--120 {
      @include pcMix {
        width: 120px;
        height: 40px;
      }
      @include smartphone {
        width: 20%;
        height: get_vh(100);
        margin: {
          right: get_vw(10);
        }
      }

    }
    &--180 {
      @include pcMix {
        width: 180px;
        height: 40px;
      }
      @include smartphone {
        height: get_vh(100);
        margin: {
          right: get_vw(30);
          left: get_vw(30)
        }
      }

    }
    &--200 {
      @include pcMix {
        width: 200px;
        height: 40px;
      }
      @include smartphone {
        height: get_vh(100);
        width: 36% !important;
        padding: get_vw(10);
      }
    }
    &--360 {
      @include pcMix {
        width: 360px;
        height: 40px;
      }
      @include smartphone {
        height: get_vh(100);
      }
    }
    &--380 {
      @include pcMix {
        width: 380px;
        height: 40px;
      }
      @include smartphone {
        height: get_vh(100);

      }

    }
    &--580 {
      @include pcMix {
        width: 580px;
        height: 40px;
      }
      @include smartphone {
        height: get_vh(100);
      }

    }
    &--660 {
      @include pcMix {
        width: 660px;
        height: 40px;
      }
      @include smartphone {
        height: get_vh(100);
      }

    }
  }
  &__radio {
    width: 80px;
  }
  &__radio--large {
    width: 130px;
  }
  &__check-box {
    @include pcMix {
      padding: {
        left: 26px !important;
      }
      @include smartphone {
      }
    }
    @include smartphone {
      width: 100%;
      float: left;
    }
  }
  &_icon-calendar {
    position: relative;
    &::after {
      content: "";
      width: 18px;
      height: 20px;
      position: absolute;
      right: 12px;
      top: 0;
      z-index: 1;
      background-image: url(../images/common/icon_calendar.png);
      background-repeat: no-repeat;
      background-position: left center;
  }
  }
  &__hight {
    &--230 {
      height: 230px !important;
    }
  }
  // SP崩れ回避用
  @include smartphone {
    &__sp-jus-btw {
      width: 100%;
      justify-content: space-between;
    }
    &__sp-width-max {
      width: 100%;
    }
    &__sp-colmn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    &__sp-text-fix-l {
      width: 100%;
      text-align: left;
      padding-bottom: 10px;
    }
    &__sp-flex-wrap {
      width: 100%;
      flex-wrap: wrap;
      height: auto;
      align-content: space-around;
      height: vh(100);
    }
    &__sp-row {
      flex-direction: row;
    }
    &_sp-check-list {
      line-height: 30px;
      height: 100px !important;
      margin-bottom: 0px;
    }
    &__sp-title-fix {
      flex-wrap: wrap;
      height: get_vh(130) !important;
      align-content: center;
    }
    &__sp-width-100 {
      width: 100% !important;
    }
    &__sp-width-80 {
      width: 80% !important;
      height: vh(100);
    }
    &__sp-width-60 {
      width: 60% !important;
      height: vh(100);
    }
    &__sp-width-70 {
      width: 70% !important;
      height: vh(100);
    }
    &__sp-width-50 {
      width: 50% !important;
    }
    &__sp-width-30 {
      width: 30% !important;
    }
    &__sp-width-25 {
      width: 25% !important;
    }
    &__sp-align-center {
      display: flex;
      align-items: center;

    }
    &__sp-flex-start {
      justify-content: flex-start;
    }
    &__sp-height {
      height: get_vh(100);
    }
    &__sp-text--ceter {
      text-align: center;
    }
    &__sp-mg-none {
      margin: {
        right: 0 !important;
        left: 0 !important;
      }
    }
  }
  &__address-txt {
    @include smartphone {
      display: flex;
      justify-content: space-between;
      margin: {
        bottom: get_vh(20);
      }
    }
  }
}