.p-property {
  &__title-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &__title {
    background-color: $green;
    color: $white;
    font-weight: normal;
    text-align: center;
    @include pcMix {
      width: 100%;
      max-width: 180px;
        font-size: 18px;
      line-height: 32px;
      padding: {
        top: 14px;
        right: 30px;
        bottom: 14px;
        left: 30px;
      }
    }
    @include smartphone {
      @include fz_vw(24);
      display: flex;
      align-items: center;
      padding: {
        right: get_vw(20);
        left: get_vw(20);
      }
    }
  }
  &__title-text {
    flex: 1;
    width: 100%;
    background-color: $beige;
    font-weight: bold;
    @include pcMix {
      font-size: 18px;
      line-height: 32px;
      padding: {
        top: 14px;
        right: 30px;
        bottom: 14px;
        left: 30px;
      }
    }
    @include smartphone {
      @include fz_vw(28);
      line-height: get_vh(34);
      padding: {
        top: get_vh(15);
        right: get_vw(30);
        bottom: get_vh(15);
        left: get_vw(30);
      }
    }
  }
  &__title-btn {
    display: block;
    background-color: $orange;
    color: $white;
    @include pcMix {
      font-size: 14px;
      width: 160px;
      height: 32px;
      line-height: 30px;
      text-align: center;
      padding: {
        left: 20px;
      }
      background-image: url(../images/common/pc_icon_arrow_white_small.png);
      background-repeat: no-repeat;
      background-position: 20px center;
    }
    @include smartphone {
      width: auto;
      height: get_vh(80);
      line-height: get_vh(76);
      padding: {
        left: get_vw(30);
      }
      background-image: url(../images/common/sp_icon_arrow_white_small.png);
      background-repeat: no-repeat;
      background-position: get_vw(30) center;
      background-size: get_vw(11);
    }

  }
  &__title-btn__box {
    background-color: $beige;
    font-weight: bold;
    @include pcMix {
      padding: {
        top: 14px;
        right: 30px;
        bottom: 14px;
      }
    }
  }
  &__box {
    @include pcMix {
      border-right: solid 4px $beige;
      border-bottom: solid 4px $beige;
      border-left: solid 4px $beige;
      padding: {
        top: 40px;
        right: 40px;
        bottom: 40px;
        left: 40px;
      }
    }
    @include smartphone {
      width: 100%;
      border: {
        right: solid get_vw(4) $beige;
        bottom: solid get_vh(4) $beige;
        left: solid get_vw(4) $beige;
      }
      padding: {
        top: get_vh(25);
        right: get_vw(34);
        left: get_vw(34);
      }
    }
  }
  &__eyecatch {
    font-weight: bold;
    @include pcMix {
      float: left;
      width: 100%;
      max-width: 492px;
      font-size: 20px;
      line-height: 28px;
      padding: {
        left: 40px;
      }
      margin: {
        bottom: 30px;
      }
      background-image: url(../images/common/pc_icon_eyecatch.png);
      background-repeat: no-repeat;
      background-position: left 5px;
    }
    @include smartphone {
      padding: {
        left: get_vw(56);
      }
      background-image: url(../images/common/sp_icon_eyecatch.png);
      background-repeat: no-repeat;
      background-position: left get_vh(5);
      background-size: get_vw(34);
      margin-bottom: get_vw(25);
    }
  }
  &__overview {
    @include pcMix{
      justify-content: space-between;
    }
    @include smartphone {
      flex-direction: column-reverse;
    }
  }
  &__price-box {
    @include pcMix {
      width: 100%;
      max-width: 492px;
      float: left;
    }
  }
  &__text-box {
    @include pcMix {
      padding: {
      }
    }
  }
  &__table {
    width: 100%;
    @include smartphone {
      margin: {
        bottom: get_vh(40);
      }
    }
    &-tr {
    }
    &-th {
      text-align: left;
      font-weight: normal;
      height: 50px;
      border: {
        bottom: solid 1px $bdBeige;
      }
      @include smartphone {
        @include fz_vw(20);
      }

    }
    &-td {
      text-align: center;
      font-weight: bold;
      height: 50px;
      border: {
        bottom: solid 1px $bdBeige;
      }
      @include smartphone {
        @include fz_vw(20);
      }
    }

    &-th--space {
      text-align: left;
      font-weight: normal;
      height: 50px;
      margin: {
        left: 10px;
      }
      border: {
        left: solid 10px $white;
        bottom: solid 1px $bdBeige;
      }
      @include smartphone {
        @include fz_vw(20);
          border: {
          bottom: solid get_vh(1) $bdBeige;
          left: solid get_vw(15) $white;
        }  
      }
    }
    &-td--space {
      text-align: center;
      font-weight: bold;
      height: 50px;
      margin: {
        right: 10px;
      }
      border: {
        bottom: solid 1px $bdBeige;
        right: solid 10px $white;
      }
      @include smartphone {
        @include fz_vw(20);
        border: {
          bottom: solid get_vh(1) $bdBeige;
          right: solid get_vw(15) $white;
        }  
      }
    }
  }
  &__image-box {
    @include pcMix {
      float: right;
      width: 412px;
    }
    @include smartphone {
      width: get_vw(620);
    }
  }
  &__features{
    @include pcMix {
      padding: {
        right: 34px;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      @include smartphone {
        justify-content: space-between;
      }
    }
    &__item {
      text-align: center;
      @include pcMix {
        width: 128px;
        height: 30px;
        line-height: 28px;
        color: $bdBeige;
        border: solid 1px $bdBeige;
        margin: {
          bottom: 6px;
          right: 6px
        }
      }
      @include smartphone {
        @include fz_vw(20);
        width: calc((100% - #{get_vw(28)}) / 3);
        height: get_vh(60);
        line-height: get_vh(58);
        color: $bdBeige;
        border: solid get_vw(2) $bdBeige;
        margin: {
          bottom: get_vh(14);
        }
      }
      &.on {
        @include pcMix {
          color: $orange;
          border: solid 1px $orange;
        }
        @include smartphone {
          color: $orange;
          border: solid 1px $orange;
        }
      }
    }
  }
  &__comment {
    & p {
      @include pcMix {
        line-height: 28px;
        margin: {
          bottom: 28px;
        }
      }
      @include smartphone {
        line-height: get_vh(34);
        margin: {
          bottom: get_vh(34);
        }
      }
    }
  }
  &__links {
    @include pcMix {
      padding: {
        right: 20px;
        left: 20px;
      }
    }
    &__list {
      width: 100%;
      display: flex;
      @include smartphone {
        flex-direction: column;
      }
    }
    &__btn {
      text-align: center;
      display: block;
      font-weight: bold;
      @include pcMix {
        width: 284px;
        height: 60px;
        margin: {
          right: 20px;
          left: 20px;
        }
      }
      @include smartphone {
        @include fz_vw(26);
        width: 100%;
        height: get_vh(120);
        line-height: get_vh(116);
        margin: {
          bottom: get_vh(30);
        }
      }
    }
  }
  &__gallery {
    &__box {
      width: 100%;
      @include pcMix {
        margin: {
          bottom: 40px;
        }
      }
      @include smartphone {
        margin: {
          bottom: get_vh(40);
        }
      }
    }
  }

}