.p-sp-search {
  padding: {
    bottom: get_vh(90);
  }
  &__title {
    display: flex;
    align-items: center;
    @include fz_vw(28);
    font-weight: bold;
    height: get_vh(100);
    line-height: get_vh(100);
  }
  &__title:after {
    content: "";
    transition: all 0.2s ease-in-out;
    display: block;
    width: get_vw(42);
    height: get_vh(42);
    background-image: url(../images/common/sp_icon_search_open.png);
    background-position: 50%;
    background-size: get_vw(42);
    margin: {
      left: get_vw(20);
    }
  }
  
  &__title.open:after {
    background-image: url(../images/common/sp_icon_search_close.png);
  }
  &__box {
    padding: {
      right: get_vw(30);
      left: get_vw(30);
    }
    margin: {
      bottom: get_vh(10);
    }
  }
  &__inner-box {
    display: none;
    padding: {
    }
  }
  // 物件種別から探す

  &__category-search {
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__item {
      @include smartphone {
        width: calc((100% - #{get_vw(30)}) / 2);
        height: get_vh(80);
        margin: {
          bottom: get_vh(30);
        }
      }
    }
    &__link {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: $white;
      @include pcMix {
        padding: {
          left: 28px;
        }
        line-height: 80px;
        background-image: url(../images/common/pc_icon_search_green.png);
        background-repeat: no-repeat;
        background-position: 20px center;  
      }
      @include smartphone {
        @include fz_vw(26);
        padding: {
          left: get_vw(40);
        }
        line-height: get_vh(80);
        background-image: url(../images/common/sp_icon_search_green.png);
        background-repeat: no-repeat;
        background-position: get_vw(20) center;  
        background-size: get_vw(30);
      }
    }
  }
  // 地図から探す
  &__map-search {
    @include pcMix {
      padding: {
        bottom: 20px;
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
    }
    &__item {
      @include pcMix {
        width: 100%;
        height: 40px;
        margin: {
          bottom: 10px;
        }
      }
      @include smartphone {
        width: 100%;
        height: get_vh(100);
        margin: {
          bottom: get_vh(30);
        }
      }
    }
    &__link {
      display: block;
      width: 100%;
      height: 100%;
      background-color: $white;
      padding: {
        left: get_vw(74);
      }
      line-height: get_vh(100);
      background-image: url(../images/common/sp_icon_map-pin_orange.png);
      background-repeat: no-repeat;
      background-position: get_vw(20) center;
      background-size: get_vw(30);
    }
  }
  &__kodawari-search {
    &__list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &__item {
      width: calc((100% - #{get_vw(30)}) / 2);
      margin: {
        bottom: get_vh(30);
      }
    }
    &__link {
    }
  }
}