.p-page-top {
  position: fixed;
  @include pcMix {
    bottom: 55px;
    right: 55px;
    width: 100px;
    height: 100px;
  }
  @include smartphone {
    bottom: get_vh(20);
    right: get_vh(20);
    width: get_vw(100);
    height: auto;
  }
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    transition : 0.5s;
}
}