.p-contact {
  font-size: get_vh(28);
  &__select {
    width: 280px;
    border: solid 1px $bdBeige;
    @include pcMix {
    }
    @include smartphone {
      width: 100%;
    }
  }
  &__select--small {
    width: 180px;
    border: solid 1px $bdBeige;
    @include pcMix {
    }
    @include smartphone {
      width: 100%;
    }
  }
  &__select--middle {
    width: 200px;
    border: solid 1px $bdBeige;
    @include pcMix {
    }
    @include smartphone {
      width: 100%;
    }
  }

  &__input-text {
    width: 100%;
    @include pcMix {
      height: 40px;
      padding: {
        left: 12px;
        right: 12px;
      }
    }
    @include smartphone {
      height: get_vh(100);
      padding: {
        left: 12px;
        right: 12px;
      }
    }
  }
  &__input-text--small {
    width: 200px;
    @include pcMix {
      height: 40px;
      padding: {
        left: 12px;
        right: 12px;
      }
    }
    @include smartphone {
      height: get_vh(100);
      padding: {
        left: 12px;
        right: 12px;
      }
    }
  }
  &__btn-box {
    width: 100%;
    display: flex;
    justify-content: space-around;
    @include pcMix {
      padding: {
        top: 60px;
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(30);
      }
    }
  }
  &__form-header-btn {
    width: 100%;
    display: flex;
    justify-content: space-around;
    @include pcMix {
      padding: {
        bottom: 60px;
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(30);
        bottom: get_vh(60);
      }
    }
  }
  &__form-box {
    display: flex;
    align-items: center;
    @include smartphone {
    }
  }
  &__form-name {
    @include pcMix {
      width: 40px;
    }
    @include smartphone {
    }
  }
  &__form-sp-box {
    display: flex;
  }
  &__sp-fix {
    @include smartphone {
     width: auto;
    }
  }
  &__fix-text {
    @include pcMix {
      height: 40px;
      line-height: 40px;
      padding-left: 10px;
      width: calc(100% - 40px);
      color: #666666;
      font-size: 14px;
    }
    @include smartphone {
      line-height: get_vh(80);
      color: #666666;
      @include fz_vw(24);
      padding-left: get_vw(40);
    }
  }
  &__agree-text {
    @include pcMix {
      font-size: 16PX;
      font-weight: bold;
      margin: {
        bottom: 60px;
      }
    }
  }
}