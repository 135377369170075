.p-cancel {
  & p {
    @include pcMix {
      margin: {
        bottom: 28px;
      }
    }
    @include smartphone {
      margin: {
        bottom: get_vh(36);
      }
    }
  }
  &__text {
    @include pcMix{
      font-size: 16px;
      line-height: 28px;
      width: calc(100% - 100px );
      margin: {
        bottom: 60px;
      }
    }
    @include smartphone{
      @include fz_vw(28);
      line-height: get_vh(34);
      margin: {
        bottom: 60px;
      }
    }
  }
  &__check {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include pcMix {
      border: solid 4px $beige;
      height: 160px;
      margin: {
        bottom: 60px;
      }
    }
      @include smartphone {
        border: solid get_vw(4) $beige;
        height: get_vh(300);
        margin: {
          bottom: 60px;
        }
        padding:  {
          right: get_vw(40);
          left: get_vw(40);
        };
      }
    &__text {
      @include pcMix {
        font-size: 16px;
        font-weight: bold;
      }
      @include smartphone {
        @include fz_vw(28);
        line-height: get_vh(34);
        font-weight: bold;
      }
    }
  }
}