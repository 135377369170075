.u-text {
    &--red {
      color: $red;
    }
    &--black{
      color: $black;
    }
    &--green{
      color: $green;
    }

    &--white{
        color: $white;
    }

    &--blue{
        color: $blue;
    }

    &--orange{
        color: $orange;
    }

    &--dark-orange {
        color: $darkOrange;
    }

    &--white{
        color: $white;
    }
    &--brown {
        color: $brown;
    }

    &--no-decoration {
        text-decoration: none;
    }

    &--disabled {
        color: $formTextDisabled;
    }

    &-size {
        &--7 {
            font-size: 7px;
        }
        &--10 {
            font-size: 10px;
        }
        &--12 {
          font-size: 12px;
      }
        &--13 {
            font-size: 13px;
        }
        &--14 {
            font-size: 14px;
        }
        &--16 {
            font-size: 16px;
        }
        &--17 {
            font-size: 17px;
        }
        &--18 {
            font-size: 18px;
        }
        &--20 {
            font-size: 20px;
        }
        &--22 {
            font-size: 22px;
        }
        &--24 {
            font-size: 24px;
        }
        &--25 {
          font-size: 25px;
        }
        &--26 {
            font-size: 26px;
        }
        &--28 {
            font-size: 28px;
        }
        &--30 {
            font-size: 30px;
        }
        &--34 {
            font-size: 34px;
        }
        &--38 {
            font-size: 38px;
        }
        &--40 {
            font-size: 40px;
        }
        &--42 {
            font-size: 42px;
        }
        &--56 {
            font-size: 56px;
        }
    }

    &-size-smartphone {
        @include smartphone {
            &--0 {
                font-size: 0;
            }
            &--10 {
                @include fz_vw(10);
            }
            &--12 {
                @include fz_vw(12);
            }
            &--14 {
                @include fz_vw(14);
            }
            &--17 {
              @include fz_vw(17);
            }
            &--18 {
             @include fz_vw(18);
            }
            &--20 {
                @include fz_vw(20);
            }
            &--22 {
                @include fz_vw(22);
            }
            &--24 {
                @include fz_vw(24);
            }
            &--26 {
              @include fz_vw(26);
          }
          &--28 {
            @include fz_vw(28);
        }
      &--30 {
                @include fz_vw(30);
            }
            &--32 {
                @include fz_vw(32);
            }
            &--34 {
                @include fz_vw(34);
            }
            &--36 {
                @include fz_vw(36);
            }
            &--38 {
                @include fz_vw(38);
            }
            &--40 {
                @include fz_vw(40);
            }
            &--42 {
                @include fz_vw(42);
            }
        }
    }

    &-letterspacing {
        &--0 {
            letter-spacing: 0em;
        }
        &--006 {
            letter-spacing: 0.06em;
        }
        &--018 {
            letter-spacing: 0.18em;
        }

    }
    &-letterspacing-smartphone {
        @include smartphone {
            &--002 {
                letter-spacing: 0.02em;
            }
        }
    }
    &-line-height {
        @include pcMix {
            &--max {
                line-height: 100%;
            }
            &--16 {
                line-height: 16px;
            }
            &--20 {
                line-height: 20px;
            }
            &--41 {
              line-height: 41px;
           }
          &--43 {
              line-height: 43px;
          }
        }

        &-smartphone {
            @include smartphone {
              &--max {
                line-height: 100%;
            }
                &--40 {
                    line-height: get_vh(40);
                }
            }
        }
    }

    &-shadow{
        &--white {
            text-shadow: 1px 1px 0 $white;
        }
        &--black-opacity-14 {
            text-shadow: 1px 1px 0 $blackOpacity14;
        }
    }

    &--bold {
        font-weight: 700;
    }
    &--smartphone-bold {
        @include smartphone {
            font-weight: 700;
        }
    }

    &--normal {
        font-weight: 400;
    }

    &--smartphone-normal {
        @include smartphone {
            font-weight: 400;
        }
    }

    &--palt{
        //プロポーショナルメトリクスを有効にする指定
        font-feature-settings: "palt" 1;
      }

    &--center {
      text-align: center;
    }

    &--left {
      text-align: left !important;
    }
    
    &--italic {
        font-style: italic;
    }

    &-fonts {
        &--roboto {
            font-family: $robotoFont;
        }
    }

}