.p-banner {
  &__in-links {
    @include pcMix {
      padding: {
        top: 40px;
        right: 40px;
        bottom: 40px;
        left: 40px;
      }
    }
    @include tablet {
      padding: {
        top: get_vh(40);
        right: get_vw(30);
        bottom: get_vh(10);
        left: get_vw(30);
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(40);
        right: get_vw(30);
        bottom: get_vh(10);
        left: get_vw(30);
      }
    }
    &__list {
      display: flex;
      justify-content: space-between;
      @include pcMix {
      }
      @include tablet {
        flex-wrap: wrap;
      }
      @include smartphone {
        flex-wrap: wrap;
      }
    }
    &__item {
      @include pcMix {
        width: calc((100% - 120px) / 4);
      }
      @include tablet {
        width: calc((100% - #{get_vw(30)}) / 2);
        margin: {
          bottom: get_vh(30);
        }
      }
      @include smartphone {
        width: calc((100% - #{get_vw(30)}) / 2);
        margin: {
          bottom: get_vh(30);
        }
      }
    }
  }
  &__ext-links {
    @include pcMix {
      padding: {
        top: 100px;
        bottom: 100px;
      }
    }
    @include tablet {
      padding: {
        top: get_vh(40);
        right: get_vw(30);
        bottom: get_vh(100);
        left: get_vw(30);
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(40);
        right: get_vw(30);
        bottom: get_vh(100);
        left: get_vw(30);
      }
    }

    &__list {
      display: flex;
      justify-content: space-between;
      @include pcMix {
        margin: {
          bottom: 40px;
        }
      }
      @include tablet {
        flex-wrap: wrap;
        margin: {
          bottom: 0;
        }
      }
      @include smartphone {
        flex-wrap: wrap;
      }
    }
    &__item {
      @include pcMix {
        width: calc((100% - 120px) / 4);
      }
      @include tablet {
        width: calc((100% - #{get_vw(30)}) / 2);
        margin: {
          bottom: get_vh(30);
        }
      }
      @include smartphone {
        width: calc((100% - #{get_vw(30)}) / 2);
        margin: {
          bottom: get_vh(30);
        }
      }
    }
  }
}