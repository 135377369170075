.l-header {
  width: 100%;
  &__contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include pcMix {
      height: 120px;
      position: relative;
    }
    @include smartphone {
      height: get_vh(140);
      padding: {
        right: get_vw(30);
        left: get_vw(30);
      }
    }
  }
  &__search {
    margin: {
      bottom: 16px;
    }
  }
}

#home .l-header {
  &__nav {
    @include pc {
      position: absolute;
      right: 50px;
      top: 40px;
      z-index: 10;
    }
  }
}
