.p-document {
  &__box {
    @include pcMix {
      padding: {
        bottom: 60px;
      }
    }
  }
  & p {
    @include pcMix {
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 30px;
    }
    @include smartphone {
      @include fz_vw(24);
      line-height: get_vh(34);
      margin-bottom: get_vh(30);
    }
  }
  & ul {
    @include pcMix {
      margin: {
        bottom: 20px;
      }
    }
    @include smartphone {
      margin: {
        bottom: get_vh(20);
      }
    }
  }
  & ol {
    @include pcMix {
      margin: {
        bottom: 20px;
      }
    }
    @include smartphone {
      margin: {
        bottom: get_vh(20);
      }
    }
  }
  & li {
    @include pcMix {
      font-size: 16px;
      line-height: 28px;
    }
    @include smartphone {
      @include fz_vw(24);
      line-height: get_vh(34);
    }
  }
 &__del {
   text-decoration: line-through;
   color: $gray;
 }
 &__table{
  &__info {
    table {
      width: 100%;
      border-bottom: solid 4px $beige;
      @include smartphone {
        @include fz_vw(24);
      }
    }
    tr {
      width: 100%;
      border: solid 4px $beige;
    }
    th {
      font-weight: normal;
      text-align: left;
      border-bottom: solid 4px $beige;

      @include pcMix {
        width: 180px;
        padding: {
          left: 60px;
        }
        height: 80px;
      }
      @include smartphone {
        width: get_vw(200);
        height: auto;
        padding: {
          left: get_vw(24);
        }
      }
    }
    td {
      border-bottom: solid 4px $beige;

      padding: {
        top: 26px;
        right: 60px;
        bottom: 26px;
      }
      @include smartphone {
        padding: {
          top: get_vh(24);
          bottom: get_vh(24);
          right: get_vw(24);
        }
      }
    }
  }

 }
}
