.u-padding {
  @include pcMix {
    &-left {
      &--6 {
        padding: {
          left: 6px;
        }
      }
      &--10 {
        padding: {
          left: 10px;
        }
      }
    }
    &-top {
      @include pcMix {
        &--30 {
          padding-top: 30px;
        }
      }
    }
    &-bottom {
      @include pcMix {
        &--none {
          padding-bottom: 0!important;
        }
        &--30 {
          padding-top: 30px;
        }
      }
    }
  } 
}