.p-footer {
  display: flex;
  @include pcMix {
    justify-content: space-between;
  }
  @include tablet {
    flex-direction: column;
    padding: {
      right: get_vw(30);
      left: get_vw(30);
    }
  }
  @include smartphone {
    flex-direction: column;
    padding: {
      right: get_vw(30);
      left: get_vw(30);
    }
  }
  &__contents {
    @include pcMix {
      width: 620px;
      padding: {
        top: 50px;
        right: 40px;
        bottom: 40px;
        left: 40px;
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(60);
        right: get_vw(30);
        bottom: get_vh(60);
        left: get_vw(30);
      }
    }
  }
  &__tell {
    flex: 1;
    @include pcMix {
      margin: {
        right: 40px;
      }
    }
    @include smartphone {
      margin: {
        bottom: get_vh(100);
      }
    }
  }
  &__info {
    display: flex;
    @include smartphone {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  &__info-box {
    @include pcMix {
      flex: 1;
      width: 100%;
      height: 214px;
      padding: {
        left: 30px;
      }
    }
  }
  &__logo {
    @include pcMix {
      width: 122px;
      height: auto;
    }
    @include smartphone {
      width: get_vw(199);
      margin: {
        bottom: get_vh(60);
      }
    }
  }
  &__info-title {
    font-weight: bold;
    @include pcMix {
      font-size: 18px;
      line-height: 100%;
      margin: {
        bottom: 15px;
      }
    }
    @include smartphone {
      @include fz_vw(26);
      line-height: get_vh(30);
      margin: {
        bottom: get_vh(24);
      }
    }
    &--small {
      font-weight: bold;
      @include pcMix {
        font-size: 13px;
        line-height: 100%;
      }
      @include smartphone {
        @include fz_vw(24);
        line-height: 100%;
        margin: {
          bottom: get_vh(60);
        }
      }
    }
  }
  &__info-text {
    @include pcMix {
      font-size: 12px;
      line-height: 20px;
      padding: {
        bottom: 10px;
      }
      margin: {
        bottom: 10px;
      }
      border-bottom: solid 1px #ccc;
    }
    @include smartphone {
      @include fz_vw(20);
      line-height: get_vh(30);
      padding: {
        bottom: get_vh(22);
      }
      margin: {
        bottom: get_vh(26);
      }
      border-bottom: solid get_vh(1) #000;

    }
    &--small {
      @include pcMix {
        font-size: 12px;
        line-height: 18px;
        margin: {
          bottom: 15px;
        }
      }
      @include smartphone {
        @include fz_vw(24);
        line-height: get_vh(34);
        margin: {
          bottom: get_vh(24);
        }
      }
    }
  }
  &__copy {
    text-align: center;
    @include pcMix {
      font-size: 9px;
      height: 210px;
      line-height: 210px;
    }
    @include smartphone {
      @include fz_vw(20);
      height: get_vh(218);
      line-height:  get_vh(218);
    }
  }
}