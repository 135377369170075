.c-property-table {
  &__td-width {
    @include pcMix {
      &--30 {
        width: 30px;
      }
      &--50 {
        width: 50px;
      }
      &--80 {
        width: 80px;
      }
      &--100 {
        width: 100px;
      }
      &--110 {
        width: 110px;
      }  
    }
    @include smartphone {
      &--30 {
        width: get_vw(40);
      }
      &--50 {
        width: get_vw(60);
      }
      &--70 {
        width: get_vw(80);
      }
      &--80 {
        width: get_vw(100);
      }
      &--100 {
        width: get_vw(150);
      }
      &--110 {
        width: get_vw(150);
      }
      
      &__sp-fix {
        &--260 {
          width: gewt_vw(260);
        }
      }
    }  
  }

}