.l-banner {
  &__contents {
    @include pcMix {
      padding: {
        top: 100px;
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(100);
      }
    }
  }
}