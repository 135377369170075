.p-side-search {
  &__box {
  }
  &__title {
    font-size: 16px;
    align-items: center;
    font-weight: bold;
    display: flex;
    height: 40px;
    padding: {
      left: 10px;
    }
  }
  &__list {
    margin: {
      bottom: 40px;
    }
  }
  &__item {
    height: 40px;
    border-bottom: dotted 1px $bdBeige ;
  }
  &__link {
    font-size: 14px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: {
      left: 36px;
    }
    background-position: 19px center;
  }
}