.p-download {
  &__container {
    display: flex;
    flex-wrap: wrap;
    @include smartphone {
      justify-content: space-between;
    }
  }
  &__box {
    @include pcMix {
      width: calc((100% - 60px) / 3);
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: solid 4px $beige;
      padding: {
        right: 24px;
        left: 24px;
      }
      margin: {
        bottom: 30px;
      }
    }
    @include smartphone {
      width: calc((100% - #{get_vw(20)}) / 2);
      height: get_vh(190);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: solid get_vw(4) $beige;
      padding: {
        right: get_vw(20);
        left: get_vw(20);
      }
      margin: {
        bottom: get_vh(20);
      }
    }
  }
  &__text {
    @include pcMix {
      font-size: 20px;
      margin: {
        bottom: 40px;
      }
    }
    @include smartphone {
      font-weight: bold;
      @include fz_vw(24);
      margin: {
        bottom: get_vh(30);
      }
    }
  }
  &__contents {
    width: 100%;
    display: flex;
  }
}