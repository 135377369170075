.l-container {
  position: relative;
  @include pcMix {
    width: 100%;
    @include pcMix {
      max-width: $contentsWidth;
      margin: {
          right: auto;
          left: auto;
      }
      padding: {
        right: 20px;
        left: 20px;
      }
    }
  }
}