.u-image {
  &--max {
    width: 100%;
    height: auto;
  }
  &--fit {
    object-fit: cover;
  }

  &-smartphone--max {

    @include smartphone {
      max-width: 100%;
      height: auto;
    }

  }
  &-smartphone--half {
    @include smartphone {
      zoom: 0.5;
    }
  }

  &--half {
    zoom: 0.5;
  }

  &--baseline {
    vertical-align: baseline;
  }

  &--middle {
    vertical-align: middle;
  }

  &--text-top {
    vertical-align: text-top;
  }
}
