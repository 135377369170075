.c-link {
  &__text {
    &--green {
      color: $green;
      @include pcMix {
        padding: {
          left: 16px;
        }
        display: block;
        background-image: url(../images/common/pc_icon_arrow_green_small.png);
        background-repeat: no-repeat;
        background-position: left center;
      }
    }
    &--white {
      color: $white;
      @include pcMix {
        padding: {
          left: 16px;
        }
        display: block;
        background-image: url(../images/common/pc_icon_arrow_white_small.png);
        background-repeat: no-repeat;
        background-position: left center;
      }
    }
  }
}