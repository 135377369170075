/**
 * @NOTE::サイト内のベースとなるものを宣言する
 */
//  * {
// 	outline: 1px solid magenta;
// }
html{
  font-size: 62.5%;
  height: 100%;
  font-feature-settings: "palt";
  @include smartphone {
    @include fz_vw(10);
  }
}

body {
  font-family: $baseFont;
  color: $black;
  position: relative;

  @include pcMix {
    height: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  @include tablet {
  }

  @include smartphone {
    height: 100%;
    @include fz_vw(24);
    line-height: get_vh(34);
  }
  
  a {
    text-decoration: none;
    color: $black;
    &:hover {
      opacity: 0.7;
      transition : 0.5s;
    }
  }
  a img {
    &:hover {
    }
    
  }
  &.fixed {
    width: 100%;
    height: 100%;
    position: fixed;
  }
}

::before,
::after {
  vertical-align: middle;
}
:focus {
  outline: none;
}

li {
  list-style: none;
}

img {
  vertical-align: bottom;
}

input,
textarea,
select {
  color: $black;
  background-color: $white;
}

table,
table tr,
table th,
table td {
  border-collapse: collapse;
}

small {
  font-size: 100%;
}
.scroll-prevent {
  /*動き固定*/
  position: fixed;
  /*奥行きを管理*/
  z-index: -1;
  /*下2つで背景を元のサイズのまま表示することができる*/
  width: 100%;
  height: 100%;
}
/*---------- clearfix ----------*/
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

input[type=checkbox]{
  display: none;
}
input[type=checkbox] + label {
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;
  position: relative;
  width: auto;
  @include smartphone {
    padding-left: 30px;
    padding : {
      top: 1px;
      bottom: 10px;
    }
    display: inline-block;
  }
}
input[type=checkbox] + label::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  content: '';
  background: $white;
  border: solid 1px $bdBeige;
  @include smartphone {
    width: 20px;
    height: 20px;
    top: -1px;
  }
}
input[type=checkbox] + label::after {
  content: '';
  display: block;
  height: 14px;
  left: 5px;
  margin-top: 0;
  position: absolute;
  top: 2px;
  width: 15px;
  background-image: none;
}
input[type=checkbox]:checked + label::after {
  content: '';
  display: block;
  height: 14px;
  left: 5px;
  margin-top: 0;
  position: absolute;
  top: 2px;
  width: 15px;
  background-image: url(../images/common/icon_check.png);
  @include smartphone {
    top: 3px;
    left: 4px;
    width: 14px;
    height: 15px;
  }

}

/* ラジオボタン */
// label ~ label {
//   margin-left: 15px;
// }

input[type=radio] {
  margin-right: 8px;
}

/* ラジオボタン */
input[type=radio] {
  display: none;
}

input[type=radio] + label {
  position: relative;
  padding-left: 25px;
  margin-right: 17px;
}

input[type=radio] + label::before {
  position: absolute;
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid $bdBeige;
  left: 0;
  top: 2px;
  @include smartphone {
    top: calc(50% - 10px);
    left: 0;
  }
}

input[type=radio] + label::after {
  position: absolute;
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $green;
  left: 5px;
  top: 7px;
  opacity: 0;
  @include smartphone {
    top: calc(50% - 5px);
    left: 5px;
  }
}

input[type=radio]:checked + label::after {
 opacity: 1;
}

input[type=text] {
  @include smartphone {
    padding: {
      right: get_vw(20);
      left: get_vh(20);
    }
  }
}
