.c-box {
  &__flex {
    display: flex;

    &--wrap {
      flex-wrap: wrap;
    }
  }

  &__inline-flex {
    display: inline-flex;
  }

  &__flex--align-center {
    align-items: center;
  }

  &__flex--justify-center {
    justify-content: center;
  }

  &__flex--justify-flex-start {
    justify-content: flex-start;
  }

  &__flex--justify-flex-end {
    justify-content: flex-end;
  }

  &__flex--justify-space-between {
    justify-content: space-between;
  }

  &__flex--direction-column {
    flex-direction: column;
  }

  &__flex--align-self-center {
    align-self: center;
  }

  &--max {
  width: 100%;
  }
  &__module-wrapper {
    width: 100%;
    @include pcMix {
      max-width: $contentsWidth;
      margin: {
          right: auto;
          left: auto;
      }
      padding: {
        right: 20px;
        left: 20px;
      }
    }
    @include smartphone {
    }

  }
  &__required {
    @include pcMix {
      display: inline-block;
      border: solid 1px $orange;
      color: $orange;
      background-color: $white;
      font-size: 10px;
      width: 40px;
      height: 20px;
      line-height: 18px;
      text-align: center;
      margin: {
        left: 20px;
      }
    }
    @include smartphone {
      display: inline-block;
      border: solid get_vw(2) $orange;
      color: $orange;
      background-color: $white;
      @include fz_vw(20);
      width: get_vw(70);
      height: get_vh(36);
      line-height: get_vh(32);
      text-align: center;
      margin: {
        left: get_vw(20);
      }
    }
  }
}