.u-margin {
  &-auto {
    margin: {
      right: auto;
      left: auto;
    }

    &--right {
      margin-right: auto;
    }

    &--left {
      margin-left: auto;
    }
  }

  &-none {
    margin: 0;
  }

  &-top {
    @include pcMix {
      &--5 {
        margin-top: 5px;
      }

      &--10 {
        margin-top: 10px;
      }

      &--14 {
        margin-top: 14px;
      }

      &--18 {
        margin-top: 18px;
      }

      &--20 {
        margin-top: 20px;
      }

      &--28 {
        margin-top: 28px;
      }

      &--32 {
        margin-top: 32px;
      }

      &--40 {
        margin-top: 40px;
      }
    }
  }

  &-right {
    @include pcMix {
      &--none {
        margin-right: 0 !important;
      }
      &--4 {
        margin-right: 4px;
      }

      &--8 {
        margin-right: 8px;
      }
      &--9 {
        margin-right: 9px;
      }

      &--10 {
        margin-right: 10px;
      }

      &--18 {
        margin-right: 18px;
      }

      &--20 {
        margin-right: 20px;
      }

      &--25 {
        margin-right: 25px;
      }

      &--30 {
        margin-right: 30px;
      }

      &--32 {
        margin-right: 32px;
      }

      &--40 {
        margin-right: 40px;
      }

      &--50 {
        margin-right: 50px;
      }
    }
  }

  &-bottom {
    @include pcMix {
      &--none {
        margin-bottom: 0 !important;
      }
      &--5 {
        margin-bottom: 5px;
      }

      &--6 {
        margin-bottom: 6px;
      }

      &--8 {
        margin-bottom: 8px;
      }

      &--10 {
        margin-bottom: 10px;
      }

      &--12 {
        margin-bottom: 12px;
      }

      &--14 {
        margin-bottom: 14px;
      }
      &--15 {
        margin-bottom: 15px;
      }

      &--16 {
        margin-bottom: 16px;
      }

      &--18 {
        margin-bottom: 18px;
      }

      &--20 {
        margin-bottom: 20px;
      }

      &--24 {
        margin-bottom: 24px;
      }

      &--25 {
        margin-bottom: 25px;
      }

      &--26 {
        margin-bottom: 26px;
      }

      &--28 {
        margin-bottom: 26px;
      }

      &--30 {
        margin-bottom: 30px;
      }

      &--35 {
        margin-bottom: 35px;
      }

      &--38 {
        margin-bottom: 38px;
      }

      &--40 {
        margin-bottom: 40px;
      }

      &--43 {
        margin-bottom: 43px;
      }

      &--45 {
        margin-bottom: 45px;
      }

      &--46 {
        margin-bottom: 46px;
      }

      &--50 {
        margin-bottom: 50px;
      }

      &--60 {
        margin-bottom: 60px;
      }

      &--64 {
        margin-bottom: 64px;
      }

      &--80 {
        margin-bottom: 80px;
      }

      &--100 {
        margin-bottom: 100px;
      }

      &--120 {
        margin-bottom: 120px;
      }

      &--150 {
        margin-bottom: 150px;
      }
      &--280 {
        margin-bottom: 280px;
      }
    }
  }

  &-left {
    @include pcMix {
      &--10 {
        margin-left: 10px;
      }
      &--20 {
        margin-left: 20px;
      }
      &--40 {
        margin-left: 40px;
      }
    }

    @include smartphone {
      &--50 {
        margin-left: 50px;
      }
    }
  }

  &-smartphone {
    @include smartphone {
      &--none {
        margin: {
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        };
      }
    }
  }

  &-top-smartphone {
    @include smartphone {
      &--12 {
        margin-top: get_vw(12);
      }

      &--20 {
        margin-top: get_vw(20);
      }

      &--24 {
        margin-top: get_vw(24);
      }
      &--30 {
        margin-top: get_vw(30);
      }

      &--40 {
        margin-top: get_vw(40);
      }
    }
  }

  &-right-smartphone {
    @include smartphone {
      &--8 {
        margin-right: get_vw(8);
      }
      
      &--10 {
        margin-right: get_vw(10);
      }

      &--16 {
        margin-right: get_vw(16);
      }

      &--20 {
        margin-right: get_vw(20);
      }

      &--23 {
        margin-right: get_vw(23);
      }

      &--30 {
        margin-right: get_vw(30);
      }

      &--32 {
        margin-right: get_vw(32);
      }

      &--34 {
        margin-right: get_vw(34);
      }

      &--40 {
        margin-right: get_vw(40);
      }

      &--250 {
        margin-right: get_vw(250);
      }
    }
  }

  &-bottom-smartphone {
    @include smartphone {
      &--5 {
        margin-bottom: get_vh(5);
      }

      &--10 {
        margin-bottom: get_vh(10);
      }

      &--20 {
        margin-bottom: get_vh(20);
      }

      &--22 {
        margin-bottom: get_vh(22);
      }

      &--24 {
        margin-bottom: get_vh(24);
      }

      &--26 {
        margin-bottom: get_vh(26);
      }

      &--30 {
        margin-bottom: get_vh(30);
      }

      &--40 {
        margin-bottom: get_vh(40);
      }

      &--42 {
        margin-bottom: get_vh(42);
      }

      &--50 {
        margin-bottom: get_vh(50);
      }

      &--60 {
        margin-bottom: get_vh(60);
      }

      &--65 {
        margin-bottom: get_vh(65);
      }

      &--80 {
        margin-bottom: get_vh(80);
      }

      &--90 {
        margin-bottom: get_vh(90);
      }

      &--100 {
        margin-bottom: get_vh(100);
      }

      &--128 {
        margin-bottom: get_vh(128);
      }
    }
  }
  &-left-smartphone {
    @include smartphone {
      &--9 {
        margin-left: get_vw(9);
      }
      &--15 {
        margin-left: get_vw(15);
      }
      &--200 {
        margin-left: get_vw(200);
      }
    }
  }

  &-left-tablet {
    @include tablet {
      &--9 {
        margin-left: get_vw(9);
      }
      &--15 {
        margin-left: get_vw(15);
      }
    }
  }

}