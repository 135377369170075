.u-display {
    &--block {
        display: block;
    }

    &--none {
      display: none;
    }

    &--inline-block {
      display: inline-block;
    }

}
.u-display-pc {
  &--none {
    @include pcMix {
      display: none;
    }
  }
}

.u-display-smartphone {
  @include smartphone {
    &--none {
      display: none;
    }
  }
}