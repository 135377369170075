#home .p-btn {
  &__header-nav {
    @include pcMix {
      width: 100px;
      height: 100px;
    }
  }
}
.p-btn {
  &__header-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $green;
    @include pcMix {
      width: 80px;
      height: 80px;
    }
    @include smartphone {
      width: get_vw(80);
      height: get_vh(80);

    }
    &--orange {
      background-color: $orange;
    }
  }
  &__new-property {
    @include pcMix {
      width: 240px;
    }
    @include smartphone {
      width: get_vw(360);
    }
  }
  &__pickup-property{
    text-align: center;
    @include pcMix {
      width: 240px;
    }
    @include smartphone {
      width: get_vw(360);
    }
  }
  &__new {
    display: block;
    color: $orange;
    background-color: $white;
    text-align: center;
    @include pcMix {
      font-size: 11px;
      width: 86px;
      height: 24px;
      line-height: 22px;
      border: solid 1px $orange;
    }
    @include tablet {
      height: get_vh(30);
      line-height: get_vh(24);
      @include fz_vw(12);
      border: solid get_vw(1) $orange;
    }
    @include smartphone {
      height: get_vh(30);
      line-height: get_vh(24);
      @include fz_vw(12);
      border: solid get_vw(1) $orange;
    }
  }
  &__page-nav {
    @include pcMix {
      width: 240px;
    }
    @include smartphone {
      width: get_vw(340);
    }
    &--small {
      @include smartphone {
        width: get_vw(300);
      }
    }
  }
  &__check-list {
    @include pcMix {
      width: 160px;
    }
    @include smartphone {
      width: get_vw(300);
    }
  }
  &__check-list--long {
    @include pcMix {
      width: 560px;
    }
    @include smartphone {
      width: 100%;
      height: auto;
      line-height: get_vh(30);
      text-align: left;
      padding: {
        top: get_vh(20);
        right: get_vw(30);
        bottom: get_vh(20);
        left: get_vw(80);
      }
    }
  }
}
