.c-btn {
  &__submit {
    @include pcMix {
      width: 100px;
      height: 40px;
      padding: {
        left: 16px;
      }
      background-color: $green;
      color: $white;
      background-image: url(../images/common/pc_icon_arrow_white_small.png);
      background-repeat: no-repeat;
      background-position: 20px center;
    }
    @include smartphone {
      width: get_vw(200);
      height: get_vh(80);
      padding: {
        left: get_vw(30);
      }
      background-color: $green;
      color: $white;
      background-image: url(../images/common/sp_icon_arrow_white_small.png);
      background-repeat: no-repeat;
      background-position: get_vw(30) center;
      background-size: get_vw(11);
    }
  }
  &__link {
    display: block;
    text-align: center;
    @include pcMix {
      width: auto;
      height: 60px;
      line-height: 58px;
      padding: {
        left: 20px;
      }
      background-color: $white;
      color: $green;
      border: solid 1px $green;
      background-image: url(../images/common/pc_icon_arrow_green.png);
      background-repeat: no-repeat;
      background-position: 20px center;
    }
    @include smartphone {
      width: auto;
      height: get_vh(80);
      line-height: get_vh(76);
      border:  solid get_vw(2) $green;
      padding: {
        left: get_vw(30);
      }
      background-color: $white;
      color: $green;
      background-image: url(../images/common/sp_icon_arrow_green.png);
      background-repeat: no-repeat;
      background-position: get_vw(30) center;
      background-size: get_vw(11);
    }

    &--green {
      display: block;
      font-weight: bold;
      @include pcMix {
        width: auto;
        height: 60px;
        line-height: 58px;
        padding: {
          left: 20px;
        }
        background-color: $green;
        color: $white;
        background-image: url(../images/common/pc_icon_arrow_white.png);
        background-repeat: no-repeat;
        background-position: 20px center;
      }
      @include smartphone {
        width: auto;
        height: get_vh(80);
        line-height: get_vh(76);
        border:  solid get_vw(2) $green;
        padding: {
          left: get_vw(30);
        }
        background-color: $green;
        color: $white;
        background-image: url(../images/common/sp_icon_arrow_white_small.png);
        background-repeat: no-repeat;
        background-position: get_vw(30) center;
        background-size: get_vw(11);
      }
    }
    &--orange {
      display: block;
      font-weight: bold;
      @include pcMix {
        width: auto;
        height: 60px;
        line-height: 58px;
        padding: {
          left: 20px;
        }
        background-color: $orange;
        color: $white;
        background-image: url(../images/common/pc_icon_arrow_white.png);
        background-repeat: no-repeat;
        background-position: 20px center;
      }
      @include smartphone {
        width: auto;
        height: get_vh(80);
        line-height: get_vh(76);
        padding: {
          left: get_vw(30);
        }
        background-color: $orange;
        color: $white;
        background-image: url(../images/common/sp_icon_arrow_white_small.png);
        background-repeat: no-repeat;
        background-position: get_vw(30) center;
        background-size: get_vw(11);
      }
    }
    &--gray {
      text-align: center;
      @include pcMix {
        width: auto;
        height: 60px;
        line-height: 58px;
        padding: {
          left: 20px;
        }
        background-color: $white;
        color: $lightGray;
        background-image: url(../images/common/pc_icon_arrow_gray.png);
        background-repeat: no-repeat;
        background-position: 20px center;
        border: solid 1px $lightGray;
      }
      @include smartphone {
        width: auto;
        height: get_vh(80);
        line-height: get_vh(76);
        padding: {
          left: get_vw(30);
        }
        background-color: $white;
        color: $lightGray;
        background-image: url(../images/common/sp_icon_arrow_gray.png);
        background-repeat: no-repeat;
        background-position: get_vw(30) center;
        background-size: get_vw(11);
        border: solid get_vw(2) $lightGray;
      }
    }
  }
  &__box {
    &--small {
      @include pcMix {
        width: 126px;
        text-align: center;
        height: 50px;
        line-height: 48px;
      }
      @include smartphone {
        @include fz_vw(20);
        width: get_vw(140);
        text-align: center;
        height: get_vh(60);
        line-height: get_vh(54);
      }
    }
    &--thin {
      @include pcMix {
        font-size: 14px;
        width: 160px;
        text-align: center;
        height: 30px;
        line-height: 28px;
        background-image: url(../images/common/pc_icon_arrow_white_small.png);

      }
      @include smartphone {
        @include fz_vw(20);
        width: get_vw(200);
        text-align: center;
        height: get_vh(60);
        line-height: get_vh(54);
      }
    }
    &--middle {
      @include pcMix {
        width: 226px;
        text-align: center;
        height: 50px;
        line-height: 48px;
      }
      @include smartphone {
        @include fz_vw(20);
        width: get_vw(267);
        text-align: center;
        height: get_vh(60);
        line-height: get_vh(58);
      }
    }
    &--large {
      @include pcMix {
        font-size: 16px;
        width: 240px;
        text-align: center;
      }
      @include smartphone {
        @include fz_vw(24);
        width: get_vw(300);
        height: get_vh(80);
        text-align: center;
      }
    }
    &--xlarge {
      @include pcMix {
        font-size: 16px;
        width: 280px;
        text-align: center;
      }
      @include smartphone {
        @include fz_vw(26);
        width: get_vw(600);
        height: get_vh(80);
        text-align: center;
      }
    }
  }
  
}