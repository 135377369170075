.l-contents {
  &__bg {
    &--green {
      background-color: $bgLightGreen;
    }
  }
  &__wrapper {
    width: 100%;
    @include pcMix {
      max-width: $contentsWidth;
      margin: {
          right: auto;
          left: auto;
      }
      padding: {
        right: 20px;
        left: 20px;
      }
    }
    @include smartphone {
      padding: {
        right: get_vw(25);
        left: get_vw(25);
      }
    }
  }
  &__page-title {
    width: 100%;
    @include pcMix {
      height: 190px;
    }
    @include smartphone {
      height: get_vh(160);
    }
    &--red {
      background-color: $red;
    }
  }
}