.p-header {
  &__nav {
    &__list {
      display: flex;
    }
    &__item {
      @include pcMix {
        margin: {
          left: 10px;
        }
      }
      @include smartphone {
        margin: {
          left: get_vw(10);
        }
      }
    }
  }
}
