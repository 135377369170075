.c-icon{
  &__square {
    &--green {
      position: relative;
      @include pcMix {
        padding: {
          left: 23px;
        }
      }
      @include smartphone {
        padding: {
          left: get_vw(24);
        }
      }
      &::after {
        content: "";
        display: block;
        background-color: $green;
        @include pcMix {
          width: 10px;
          height: 10px;
          position: absolute;
          left: 0;
          top: calc(50% - 5px);
        }
        @include smartphone {
          width: get_vw(10);
          height: get_vh(10);
          position: absolute;
          left: 0;
          top: get_vh(10);
        }
      }
    }
  }
}