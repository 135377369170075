.p-menu {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  -webkit-overflow-scrolling: touch;
  cursor: pointer;
  z-index: 1;
  @include pcMix {
    height: calc(100% - 120px);
    top: 120px;  
  }
  @include smartphone {
    height: calc(100% - #{get_vh(140)});
    top: get_vh(140);
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include pcMix {
      max-width: 680px;
      margin: {
        right: auto;
        left: auto;
      }
      padding: {
        top: 120px;
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(70);
      }
    }
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include pcMix {
      padding: {
        bottom: 20px;
      }
    }
    @include smartphone {
      padding: {
        right: get_vw(30);
        bottom: get_vh(30);
        left: get_vw(30);
      }
      justify-content: space-between;
    }
  }
  &__item {
    @include pcMix {
      width: 300px;
      height: 100px;
      margin: {
        right: 20px;
        bottom: 40px;
        left: 20px;
      }
    }
    @include smartphone {
      width: calc((100% - #{get_vh(30)}) / 2);
      height: get_vh(140);
      margin: {
        bottom: get_vh(30);
      }
    }

  }
  &__link {
    color: $green;
    width: 100%;
    height: 100%;
    display: block;
    background-color: $white;
    text-align: center;
    @include pcMix {
      font-size: 16px;
      line-height: 98px;
      padding-left: 40px;  
      border: solid 1px $green;
      background-image: url(../images/common/pc_icon_arrow_green.png);
      background-repeat: no-repeat;
      background-position: 40px center;
    }
    @include smartphone {
      @include fz_vw(26);
      line-height: get_vh(136);
      padding-left: get_vw(30);  
      border: solid get_vh(2) $green;
      background-image: url(../images/common/sp_icon_arrow_green.png);
      background-repeat: no-repeat;
      background-position: get_vw(30) center;
      background-size: get_vw(11);
    }
  }
  &__btn-close {
    @include pcMix {
      display: block;
      height: 30px;
      line-height: 30px;
      font-size: 16px;
      font-weight: bold;
    }
    @include smartphone {
      @include fz_vw(26);
      display: block;
      height: get_vh(30);
      line-height: get_vh(30);
      font-weight: bold;
    }
  }
}
