.c-line {
  @mixin dashed {
    background-size: 3px 1px;          /* グラデーションの幅・高さを指定 */
    background-position: left bottom;  /* 背景の開始位置を指定 */
    background-repeat: repeat-x;       /* 横向きにのみ繰り返す */
  }
  &-dashed-1x2 {
    &--lightGray {
      @include smartphone {
        @include dashed;
        background-image : linear-gradient(to right, #B2B2B2, #B2B2B2 1px, transparent 1px, transparent 3px);  /* 幅2の線を作る */
      }
    }
    &--green {
      @include dashed;
      background-image : linear-gradient(to right, #D2E5B2, #D2E5B2 1px, transparent 1px, transparent 3px);  /* 幅2の線を作る */
    }  
    &--news {
      @include dashed;
      @include pcMix {
        background-image : linear-gradient(to right, #D2E5B2, #D2E5B2 1px, transparent 1px, transparent 3px);  /* 幅2の線を作る */
      }
      @include smartphone {
        background-image : linear-gradient(to right, #B2B2B2, #B2B2B2 1px, transparent 1px, transparent 3px);  /* 幅2の線を作る */
      }
    }  
  }
}