.p-image-slider {
    background: $lightGray;

    @include pcMix {
    display: flex;
      padding: {
        top: 20px;
        right: 20px;
        bottom: 20px;
        left: 20px;
      };

      width: 100%;
    }
  
    @include smartphone {
      padding: {
        top: get_vh(30);
        right: get_vw(20);
        bottom: get_vh(30);
        left: get_vw(20);
      };

      width: 100%;
    }
  
  &__main {
    @include pcMix {
      display: flex;
      justify-content: center;
      padding: {
        top: 0;
        right: 20px;
        bottom: 0;
        left: 0;
      }
      margin: {
        bottom: 20px;
      }
    }
    @include smartphone {
      display: flex;
      justify-content: center;
      margin: {
        bottom: get_vh(30);
      }
    }
  }
  &__main-image {
    @include pcMix {
      width: 100%;
    }
    @include smartphone {
      width: 100%;
    }
  }
  
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    @include pcMix {
      width: 160px;
    }
    
    @include smartphone {
      width: 100%;
    }
  }
  
  &__item {
    @include pcMix {
      width: 100%;
      height: auto;
      padding: 10px;
      margin: {
        bottom: 20px;
      }
      border: solid 1px $gray;
      display: flex;
      justify-content: center;
      cursor: pointer;

      &:hover {
        border: solid 3px $blue;
        padding: 8px;
      }
      box-sizing: border-box;
    }
    
    @include smartphone {
      width: get_vw(120);
      height: get_vh(120);
      padding: {
        top: get_vh(10);
        left: get_vw(10);
        bottom: get_vh(10);
        right: get_vw(10);
      }
      margin: {
        left: get_vw(10);
        bottom: get_vh(20);
        right: get_vh(10)
      }
      display: flex;
      border: solid get_vw(2) $gray;
      justify-content: center;
      cursor: pointer;

      &:hover {
        border: solid get_vw(6) $blue;
        padding: {
          top: get_vh(6);
          left: get_vw(6);
          bottom: get_vh(6);
          right: get_vw(6);
        }
      }
    }
  }

  &__thumbnail-image {
    
    @include pcMix {
      height: auto;
    }
    
    @include smartphone {
      height: get_vh(100);
    }

  }
}