.p-application-form {
   &__download-box{
    @include pcMix {
      width: 100%;
      height: 180px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: solid 4px $beige;
      padding: {
        right: 24px;
        left: 24px;
      }
      margin: {
        bottom: 30px;
      }
    }
    @include smartphone {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: solid get_vw(4) $beige;
      padding: {
        top: get_vh(30);
        right: get_vw(30);
        left: get_vw(30);
        bottom: get_vh(30);
      }
      margin: {
        bottom: get_vh(20);
      }
    }
  }
  &__box {
    @include pcMix {
      width: 100%;
      max-width: 1080px;
      font-size: 16px;
    }
    @include smartphone {
      width: 100%;
      @include fz_vw(24);
    }
  }
  &__header {
    @include pcMix {
      height: 110px;
      padding: 40px;
      margin: {
        bottom: 10px;
      }
    }
    @include smartphone {
      @include fz_vw(24);
      padding: get_vw(30);
      margin-bottom: get_vh(20);
    }
    background-color: $white;
  }
  &__contents {
    @include pcMix {
    padding: 40px;
    }
    @include smartphone {
      padding: get_vw(30);
    }
    background-color: $white
  }
  &__check-list {
    @include pcMix {
      padding: {
        top: 34px;
      }
    }
    &__item {
      @include pcMix {
        font-size: 16px;
        min-height: 28px;
        margin: {
          bottom: 28px;
        }
      }
      @include smartphone {
        @include fz_vw(24);
        line-height: get_vh(32);
        margin: {
          bottom: get_vh(32);
        }

      }
    }
    &__label {
      display: block;
      @include pcMix {
        padding: {
          left: 38px !important;
        }
      }
      @include smartphone {
        padding: {
          left: get_vh(46) !important;
        }
      }
    }
    &__btn-box {
      padding: {
        top: 10px;
      }
      text-align: center;
    }
    &__inner-box {
      @include pcMix {
        padding: {
          top: 28px;
          left: 40px;
        }
      }
      @include smartphone {
        padding: {
          top: get_vh(20);
          left: get_vw(40);
        }
      }
      & li {
        @include pcMix {
          line-height: 30px;
        }
        @include smartphone {
          line-height: get_vh(40);
        }
      }
    }
  } 
  &__demo-box {
  }
}