.p-top {
  &__mv-box {
    position: relative;
    width: 100%;
    @include pcMix {
      height: 738px;
    }
    @include smartphone {
      height: get_vh(740);
    }
  }
  &__mv {
    @include pcMix {
      width: 100%;
      height: 660px;
      background-image: url(../images/top/pixta_1280_pc.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    @include smartphone {
      width: 100%;
      height: get_vh(640);
      background-image: url(../images/top/pixta_1024_sp.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    &__text-img {
      @include pcMix {
        width: 100%;
        max-width: 1009px;
        position: absolute;
        left: 30px;
        bottom: 0;
      }
      @include smartphone{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    &__text {
      @include pcMix {
        width: 100%;
        max-width: 120px;
        position: absolute;
        right: 120px;
        top: 60px;
      }
      @include smartphone {
        width: 100%;
        max-width: get_vw(120);
        position: absolute;
        right: get_vw(40);
        top: get_vh(40);
      }
    }
  
  }

  &__search {
    display: flex;
    flex-wrap: wrap;
    @include pc {
      justify-content: space-between;
    }
    @include tablet {
    }
    @include smartphone {
      flex-direction: column;
      @include fz_vw(26);
    }
    &-box {
      @include pcMix {
        width: 50%;
        display: flex;
        justify-content: space-between;
      }
      @include tablet {
        width: 100%;
      }
      &--large {
        @include pc {
          width: 100%;
          max-width: 600px;
          margin: {
            right: 20px;
          }
          padding: {
            top: 30px;
            right: 20px;
            bottom: 10px;
            left: 20px;
          }  
        }  
        @include tablet {
          width: 100%;
          padding: {
            top: 30px;
            right: 30px;
            bottom: 10px;
            left: 30px;
          }
          margin: {
            bottom: 20px;
          }
        }
        @include smartphone {
          padding: {
            top: get_vh(40);
            right: get_vw(15);
            bottom: get_vh(10);
            left: get_vw(15);
          }
        }    
      }
      &--small {
        @include pcMix {
          width: 50%;
          max-width: 300px;
          margin: {
          }
          padding: {
            top: 30px;
            right: 30px;
            left: 30px;
          }
        }
        @include tablet {
          width: calc(100% / 2);
          max-width: 100%;
        }
        @include smartphone {
          padding: {
            top: get_vh(40);
            right: get_vw(30);
            bottom: get_vh(40);
            left: get_vw(30);
          }
        }
      }
      &--double {
        display: flex;
        @include pcMix {
          justify-content: space-between;
        }
        @include smartphone {
          flex-direction: column;
        }
      }
    }
  }
  // 物件種別から探す
  &__category-search {
    &__list {
      display: flex;
      flex-wrap: wrap;
      @include pcMix {
      }
    }
    &__item {
      @include pcMix {
        width: calc(50% - 20px);
        height: 80px;
        margin: {
          right: 10px;
          bottom: 20px;
          left: 10px;
        }
      }
      @include smartphone {
        width: calc(50% - #{get_vw(30)});
        height: get_vh(80);
        margin: {
          right: get_vw(15);
          bottom: get_vh(30);
          left: get_vw(15);
        }
      }
    }
    &__link {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: $white;
      @include pcMix {
        font-size: 16px;
        padding: {
          left: 28px;
        }
        line-height: 80px;
        background-image: url(../images/common/pc_icon_search_green.png);
        background-repeat: no-repeat;
        background-position: 20px center;  
      }
      @include smartphone {
        @include fz_vw(26);
        padding: {
          left: get_vw(40);
        }
        line-height: get_vh(80);
        background-image: url(../images/common/sp_icon_search_green.png);
        background-repeat: no-repeat;
        background-position: get_vw(20) center;  
        background-size: get_vw(30);
      }
    }
  }
  // 地図から探す
  &__conditional-search {
    @include pcMix {
      padding: {
        bottom: 30px;
      }
    }
  }
  // 地図から探す
  &__map-search {
    @include pcMix {
      padding: {
        bottom: 20px;
      }
    }
    &__list {
    }
    &__item {
      @include pcMix {
        width: 100%;
        height: 40px;
        margin: {
          bottom: 10px;
        }
      }
      @include smartphone {
        width: 100%;
        height: get_vh(100);
        margin: {
          bottom: get_vh(30);
        }
      }
    }
    &__link {
      display: block;
      width: 100%;
      height: 100%;
      background-color: $white;
      @include pcMix {
        padding: {
          left: 34px;
        }
        line-height: 40px;
        background-image: url(../images/common/pc_icon_map-pin_orange.png);
        background-repeat: no-repeat;
        background-position: 11px center;  
      }
      @include smartphone {
        padding: {
          left: get_vw(74);
        }
        line-height: get_vh(100);
        background-image: url(../images/common/sp_icon_map-pin_orange.png);
        background-repeat: no-repeat;
        background-position: get_vw(20) center;
        background-size: get_vw(30);
      }
    }
  }
  &__kodawari-search {
    @include pcMix {
      padding: {
        top: 60px;
        bottom: 84px;
      }
    }
    @include tablet {
      padding: {
        top: get_vh(40);
        bottom: get_vh(22);
      }
    }
    @include smartphone{
      padding: {
        top: get_vh(40);
        right: get_vw(30);
        bottom: get_vh(22);
        left: get_vw(30);
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      @include tablet {
        justify-content: space-around;
        &::after {
          display: block;
          content:"";
          width: get_vw(218);
        }
      }

      @include smartphone {
        justify-content: space-between;
        &::after {
          display: block;
          content:"";
          width: get_vw(218);
        }
      }
    }
    &__item {
      @include pcMix {
        width: 144px;
        height: 144px;
        margin: {
          right: 16px;
          bottom: 16px;
        }
      }
      @include tablet {
        width: get_vw(218);
        height: auto;
        margin: {
          right: 0;
          bottom: get_vh(18);
        }
      }
      @include smartphone {
        width: get_vw(218);
        margin: {
          bottom: get_vh(18);
        }
      }
    }
    &__link {
      display: block;
      width: 100%;
    }
  }
  &__new-property {
    @include pcMix {
      padding: {
        top: 110px;
      }
    }
    @include smartphone{
      padding: {
        top: get_vh(40);
        right: get_vw(30);
        bottom: get_vh(22);
        left: get_vw(30);
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include tablet {
        justify-content: space-around;
        &::after {
          display: block;
          content:"";
          width: 290px;
        }
      }

    }
    &__item {
      @include pcMix {
        width: 290px;
        width: calc((100% - 120px) / 4);
        margin: {
          bottom: 40px;
        }
      }
      @include tablet {
        width: get_vw(330);
      }
      @include smartphone {
        width: get_vw(330);
        margin-bottom: get_vh(30);
      }
    }
    &__figure {
      position: relative;
    }
    &__title {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: rgba($color: #65b820, $alpha: 0.8);
      color: $white;
      @include pcMix {
        font-size: 14px;
        padding: {
          top: 9px;
          right: 14px;
          bottom: 9px;
          left: 14px;
        }
      }
      @include smartphone {
        @include fz_vw(24);
        padding: {
          top: get_vh(14);
          right: get_vw(20);
          bottom: get_vh(14);
          left: get_vw(20);
        }
      }
    }
    &__text-box {
      background-color: $bgBeige;
    }
    &__text-head {
      text-align: center;
      font-weight: bold;
      display: flex;
      align-items: center;
      @include pcMix {
        justify-content: center;
        font-size: 22px;
        line-height: 100%;
        height: 60px;
        padding: {
          right: 10px;
          left: 10px;
        }
      }
      @include smartphone {
        justify-content: space-between;
        @include fz_vw(28);
        height: get_vh(80);
        padding: {
          right: get_vw(20);
          left: get_vw(20);
        }
      }
    }
    &__text {
      text-align: center;
      @include pcMix {
        padding: {
          right: 10px;
          bottom: 24px;
          left: 10px;
        }
      }
      @include smartphone {
        padding: {
          right: get_vw(20);
          bottom: get_vh(24);
          left: get_vw(20);
        }
      }
    }
  }
  &__pickup-property {
    @include pcMix {
      padding: {
        bottom: 180px;
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(40);
        right: get_vw(30);
        bottom: get_vh(22);
        left: get_vw(30);
      }
    }
    &__box {
      @include pcMix {
        display: flex;
        flex-wrap: wrap;
      }
    }
    &__image-box {
      display: flex;
      @include pcMix {
        margin: {
          bottom: 20px;
        }
      }
      @include tablet {
        flex-wrap: wrap;
      }
      @include smartphone {
        flex-direction: column;
      }
    }
    &__main-image {
      @include pcMix {
        width: 400px;
        height: 400px;
        margin: {
          right: 20px;
        }
      }
      @include tablet {
        width: 100%;
        height: auto;
        margin: {
          right: 0;
          bottom: get_vh(30);
        }
      }
      @include smartphone {
        width: 100%;
        height: auto;
        margin: {
          bottom: get_vh(30);
        }
      }
    }
    &__thumbnail-list {
      display: flex;
      flex-wrap: wrap;
      @include pcMix {
        width: 440px;
        height: 400px;
      }
      @include tablet {
        width: 100%;
        height: auto;
        justify-content: space-between;
      }
      @include smartphone {
        justify-content: space-between;
      }
    }
    &__thumbnail-item {
      @include pcMix {
        width: 190px;
        height: 190px;
        margin: {
          right: 20px;
          bottom: 20px;
        }
      }
      @include tablet {
        width: calc((100% - #{get_vw(30)}) / 2);
        height: auto;
        margin: {
          right: 0;
          bottom: get_vh(30);
        }
      }
      @include smartphone {
        width: calc((100% - #{get_vw(30)}) / 2);
        height: auto;
        margin: {
          bottom: get_vh(30);
        }
      }
    }
    &__info-box {
      @include pcMix {
        width: 100%;
        max-width: 420px;
        height: 400px;
        padding: {
          top: 32px;
          right: 40px;
          bottom: 40px;
          left: 40px;
        }
      }
      @include tablet {
        max-width: 100%;
      }
      @include smartphone {
        padding: {
          top: get_vh(32);
          right: get_vw(30);
          bottom: get_vh(30);
          left: get_vw(30);
        }
      }
    }
    &__head {
      @include smartphone {
        display: flex;
      }
    }
    &__info-title {
      text-align: center;
      font-weight: bold;
      @include pcMix {
        font-size: 30px;
        line-height: 34px;
        margin: {
          bottom: 16px;
        }
      }
      @include smartphone {
        @include fz_vw(34);
        width: 50%;
        text-align: center;
        margin: {
          bottom: get_vh(34);
        }
      }
    }
    &__info-text {
      text-align: center;
      @include pcMix {
        padding: {
          bottom: 24px;
        }
      }
      @include smartphone {
        margin: {
          bottom: get_vh(34);
        }
      }
    }
  }
  &__news {
    @include pcMix {
      padding: {
        top: 60px;
        bottom: 90px;
      }
    }
    @include tablet {
      padding: {
        top: get_vh(40);
        right: get_vw(30);
        bottom: get_vh(40);
        left: get_vw(30);
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(40);
        right: get_vw(30);
        bottom: get_vh(40);
        left: get_vw(30);
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__item {
      @include pcMix {
        width: 600px;
        margin: {
          bottom: 50px;
        }
      }
      @include tablet {
        width: 100%;
        margin: {
          bottom: get_vh(24);
        }
      }
      @include smartphone {
        width: 100%;
        margin: {
          bottom: get_vh(24);
        }
      }
    }
    &__link {
      display: flex;
      justify-content: space-between;
    }
    &__date {
      display: block;
      text-align: center;
      box-sizing: border-box;
      @include pcMix {
        width: 86px;
        line-height: 30px;
        margin: {
          bottom: 5px;
        }
      }
      @include tablet {
        text-align: left;
        width: get_vw(126);
        line-height: get_vh(40);
        margin: {
          bottom: get_vh(10);
        }

      }
      @include smartphone {
        width: get_vw(126);
        line-height: get_vh(40);
        margin: {
          bottom: get_vh(10);
        }

      }
    }
    &__text {
      @include pcMix {
        width: 480px;
        line-height: 30px;
      }
      @include tablet {
        width: get_vw(530);
        line-height: get_vh(40);
      }
      @include smartphone {
        width: get_vw(530);
        line-height: get_vh(40);
      }
    }
  }
}
