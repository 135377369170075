.p-search {
  justify-content: space-between;
  padding: {
    top: 20px;
    right: 30px;
    bottom: 10px;
    left: 30px;
  }
  &__form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__title {
    width: 160px;
  }
  &__inner-block {
    display: flex;
    align-items: center;
    margin: {
      right: 10px;
      bottom: 10px;
    }
  }
  &__select {
    width: 140px;
    &--small {
      width: 120px;
    }
  }
  &__text {
    width: 200px;
    margin: {
      right: 10px;
    }
  }
  &__btn {
    display: block;
  }
 }
