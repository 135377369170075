.l-under-contents {
  width: 100%;
  @include smartphone {
    width: 100%;
    padding: {
      right: get_vw(30);
      left: get_vw(30);
    }
  }
  &__2col {
    @include pcMix {
      display: flex;
    }
  }
  &__title {
    @include pcMix {
      margin: {
        bottom: 40px;
      }
    }
    @include smartphone {
      margin: {
        bottom: get_vh(40);
      }
    }
  }
  &__main {
    @include pcMix {
      flex: 1;
      width: 100%;
      height: 100%;
    }
  }
  &__page-nav {
    display: flex;
    justify-content: space-between;
    @include pcMix {
      padding: {
        top: 40px;
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(60);
      }
    }
    &--center {
      justify-content: center;
    }
  }
}