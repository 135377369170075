.u-width {
  @include pcMix {
  &--30 {
    width: 30px;
  }
  &--40 {
    width: 100%;
    max-width: 40px;
  }
  &--50 {
    width: 50px;
  }
  &--80 {
    width: 80px;
  }
  &--83 {
    width: 83px;
  }
  &--100 {
    width: 100px;
  }
  &--110 {
    width: 110px;
  }
  &--120 {
    width: 120px;
  }
  &--136 {
    width: 136px;
  }
  &--186 {
    width: 186px;
  }
  &--206 {
    width: 206px;
  }
  &--400 {
    width: 100%;
    max-width: 400px;
  }
  &--492 {
    width: 100%;
    max-width: 492px;
  }
  &--395 {
    width: 100%;
    max-width: 395px;
  }
  &--305 {
    width: 100%;
    max-width: 305px;
  }
  }
  @include smartphone {
    &-smartphone {
      &--30 {
        width: get_vw(30);
      }
      &--50 {
      width: get_vw(50);
      }
     }
  
  }
}