.c-title {
  &--large {
    @include pcMix {
      font-size: 24px;
      line-height: 50px;
      height: 50px;
      border-left: solid 2px $green; 
      padding-left: 16px;
    }

    @include smartphone {
      @include fz_vw(28);
      line-height: get_vh(50);
      height: get_vh(50);
      border-left: solid get_vw(2) $green; 
      padding-left: get_vw(16);
    }
  }
  &--small {
    @include pcMix {
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      height: 30px;
      border-left: solid 2px $green; 
      padding-left: 16px;
      margin: {
        bottom: 3px;
      }
    }

    @include smartphone {
      @include fz_vw(28);
      line-height: get_vh(50);
      height: get_vh(50);
      border-left: solid get_vw(2) $green; 
      padding-left: get_vw(16);
    }
  }
  &--beige {
    background-color: $beige;
    @include pcMix {
      padding: {
        top: 20px;
        right: 20px;
        bottom: 20px;
        left: 20px;
      }
    }
    @include smartphone {
      padding: {
        top: get_vh(24);
        right: get_vw(30);
        bottom: get_vh(24);
        left: get_vw(30);
      }
    }
  }
  &__search {
    @include pcMix {
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      padding: {
        left: 30px;
      }
      margin: {
        bottom: 10px;
      }
      background-image: url(../images/common/pc_icon_search_green.png);
      background-repeat: no-repeat;
      background-position: left center;
    }
  }
}