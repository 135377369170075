/**
 * @NOTE::変数を宣言する
 */

// Break Point
$pcMinWitdh: 1024px;
$tabletMinWidth: 768px;
$smartphoneMaxWidth: 767px;
$smartphoneMinMaxWidth: 481px;

// Content Width
$contentsWidth: 1320px;
$contentsPadding: 20px;

// Fonts
$gothicFont:'メイリオ', 'Meiryo','MS ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif;
$notoSerifFont: 'Noto Serif JP', serif;
$notoSansFont: 'Noto Sans JP', 'MS ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif;
$robotoFont: 'Roboto', sans-serif;
$sawarabiFont: 'Sawarabi Mincho', sans-serif;
$baseFont: $notoSansFont;

// Color
$white: #fff;
$black: #333333;
$brown: #8c6f50;
$beige: #efefe5;
$lightGray: #cecece;


$red: #D7282D;
$blue: #1482AA;
$green: #65b820;
$orange: #ff641e;
$darkGray: #333333;
$yellow: #F0E650;

$courseRed: #C24441;
$courseBlue: #35729B;
$courseYellow: #DCA325;

$bgGreen: #EEF5E2;
$bgLightGreen: #F8FBF3;
$bgFotterGreen: #FCFBF7;
$bgLightBeige:#cecebf;
$bgBeige: #efefe5;

$bdBeige: #cecebf;
$bdGray: #dfdfd5;

$gray: #B2B2B2;
$darkGray: #4C4C4C;
$darkOrange: #f07a15;
$navy: #09276b;
$formBaseLightGray: #fafafa;
$formTextDisabled: #999999;
$fileError: #dd4f68;

// shadowColor
$blackOpacity14: rgba($black,0.14)
