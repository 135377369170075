.p-topic-path {
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    @include pcMix {
      height: 24px;
    }
    @include smartphone {
      border-top: solid $bgBeige get_vh(2) ;
      padding: {
        right: get_vw(30);
        left: get_vw(30);
      }
    }
  }
  &__item {
    display: flex;
    @include pcMix {
      font-size: 13px;
      height: 22px;
      line-height: 22px;
    }
    @include smartphone {
      @include fz_vw(24);
      height: get_vh(80);
      line-height: get_vh(80);
    }
    &:after {
      content: '>';
      text-align: center;
        @include pcMix {
          height: 22px;
          line-height: 22px;
          width: 30px;
        }
        @include smartphone {
          width: get_vw(40);
        }
      }
    &:last-child::after {
      content: none;
    }
  }
  &__link {
    display: block;
  }
}