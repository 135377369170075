/**
 * @NOTE::関数、ミックスインを宣言する
 */

/**
 * PC用メディアクエリ
 */
@mixin pc {
  @media screen and (min-width:$pcMinWitdh) {
    @content;
  }
}

/**
 * PC＋タブレット用メディアクエリ
 */
@mixin pcMix {
  @media screen and (min-width:$tabletMinWidth) {
    @content;
  }
}

/**
 * タブレット用メディアクエリ
 */
@mixin tablet {
  @media screen and (min-width:$tabletMinWidth) and (max-width:$pcMinWitdh) {
    @content;
  }
}

/**
 * スマートフォン用メディアクエリ
 */
@mixin smartphone {
  @media screen and (max-width:$smartphoneMaxWidth) {
    @content;
  }
}

/**
 * スマートフォンランドスケープ用メディアクエリ
 */
@mixin smartphoneLandscape {
  @media screen and (min-width:$smartphoneMinMaxWidth) and (max-width:$smartphoneMaxWidth) {
    @content;
  }
}

/**
 * スマートフォン最小サイズ用メディアクエリ
 */
@mixin smartphoneMin {
  @media screen and (max-width:$smartphoneMinMaxWidth) {
    @content;
  }
}

/**
 * パーセントでwidthを指定する
 *
 * @param $size      指定サイズ(px)
 * @param $baseWidth サイズ
 */
 @mixin percent_width($size, $baseWidth:750) {
  width: get_percent($size, $baseWidth) * 1%;
}

/**
 * パーセントでmax-widthを指定する
 *
 * @param $size      指定サイズ(px)
 * @param $baseWidth サイズ
 */
@mixin percent_max_width($size, $baseWidth:750) {
  max-width: get_percent($size, $baseWidth) * 1%;
}

/**
 * vwを元にフォントサイズを指定する
 *
 * @param $font_size フォントサイズ
 */
 @mixin fz_vw($font_size:10) {
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

/**
 * VW取得用関数
 *
 * @param $size     指定サイズ(px)
 * @param $viewport viewportサイズ
 */
@function get_vw($size, $viewport:750) {
  @return (100 / $viewport) * $size * 1vw;
}

/**
 * VH取得用関数
 *
 * @param $size     指定サイズ
 * @param $viewport viewportサイズ
 */
@function get_vh($size, $viewport:750) {
  @return (100 / $viewport) * $size * 1vw;
}

/**
 * widthに対してのpxでのパーセントを返す
 *
 * @param $size     指定サイズ
 * @param $viewport viewportサイズ
 */
@function percent($size, $baseWidth:750) {
  @return get_percent($size, $baseWidth) * 1%;
}

/**
 * $baseに対してのパーセントを返す
 *
 * @param $size 指定サイズ
 * @param $base サイズ
 */
@function get_percent($size, $base:750) {
  @return $size / $base * 100;
}
